import React from 'react';

import ItemRecord from '../../components/views/settings/itemRecord';
import { LayoutLoggedIn } from '../../components/views/layout';

export default function() {
    return (
        <LayoutLoggedIn>
            <ItemRecord />
        </LayoutLoggedIn>
    );
}